<template>
  <div class="b-order-thankyou">
    <div class="ready-dive-in">
      <h2 class="text-center font-weight-bold">Ready To Dive In!</h2>
      <h4 class="mt-4 text-center">Your <b>{{ agencyTitle }}</b> account is all set up (hooray!).</h4>
      <p class="mt-4 text-center">We've sent you an email to confirm your email address - click the link in that email to
        access your account.</p>
      <p class="mt-4 text-center">If you didn't get the email, or you need assistance, contact us at: <a
          :href="`mailto:${supportEmail}?Subject=Free Trial Signup Question`" target="_top" class="text-primary">{{
            supportEmail }} </a> or text us at {{ supportPhone | phone }}</p>
      <div class="mt-4 text-center">
        <router-link :to="{ name: 'login' }" class="btn btn-primary">Login</router-link>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  components: {
  },

  data() {
    return {
    }
  },

  mounted() {

  },

  methods: {
  },

  computed: {
    agencyTitle() {
      return this.sitedata && this.sitedata.title
    },

    supportEmail() {
      return this.sitedata && this.sitedata.supportEmail
    },

    supportPhone() {
      return this.sitedata && this.sitedata.supportPhone
    },

    sitedata() {
      return this.$store.state.app.sitedata
    },
  },
}
</script>
  
<style lang="scss">
.b-order-thankyou {
  width: 840px;
  color: #070707;
  margin: 0 auto;
  padding: 50px 30px;

  .ready-dive-in {
    p {
      font-size: 18px;
    }
  }
}</style>
  